<!-- not in use -->
<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz oznaczyć jako {{ targetTableName }} ?
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        :loading="isProcessing"
        v-shortkey="{enter: ['enter']}"
        @shortkey.native="updateValue()"
        @click="updateValue()"
      >
        Przenieś
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  props: {
    tableName: {
      type: String,
      default: 'debrisTypes'
    }
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
    }),
    isProcessing () {
      return this.$store.state[this.tableName].isProcessing
    },
    targetTableName () {
      return this.tableName === 'debrisTypes' ? 'typ kruszywa' : 'typ odpadu'
    }
  },
  methods: {
    ...mapActions({
      editDebrisType: 'debrisTypes/editItem',
      getDebrisTypes: function (dispatch) {
        return dispatch(`${this.tableName}/getItems`)
      },
      closeDialog: 'layout/closeDialog',
    }),
    updateValue () {
      this.editDebrisType({
        tableName: 'debrisTypes',
        params: {
          aggregate: this.tableName === 'debrisTypes'
        },
        id: this.item.id,
      }).then(() => {
        this.getDebrisTypes()
        this.closeDialog()
      })
    }
  }
}
</script>
